import {createStore, combineReducers, applyMiddleware} from 'redux'


const initialState = { sidebarShow: 'responsive' }
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const initiallogindata ={ loggedin: false, loggedout:false, memberid:'', loginfailed:'', password:'',  token:'', roles:'', URL:'https://iback.waterithailand.com'}
const loginReducer = (state=initiallogindata, action) =>{
  switch(action.type){
    case "setLoginFailed": state ={...state, loginfailed: action.payload }; break;
    case "setLogin": state ={...state, loggedin: action.payload }; break;
    case "setLogout": state ={...state, loggedout: action.payload}; break;
    case "setMemberid": state ={ ...state, memberid: action.payload }; break;
    case "setPassword": state ={ ...state, password: action.payload }; break;
    case "setToken": state ={ ...state, token: action.payload }; break;
    case "setRoles": state ={ ...state, roles: action.payload }; break;
    default:
  }
  return state;
}

const initialdata ={ userdatas:[] , admindatas:[], machinedatas:[], machineadmindatas:[], investdatas:[],csvpayment:[],csvpaymentr:[]}
const DataReducer = (state=initialdata, action) =>{
  switch(action.type){
    case "setUserstate": state ={...state, userdatas: action.payload }; break;
    case "setAdminstate": state ={...state, admindatas: action.payload }; break;
    case "setMachinestate": state ={...state, machinedatas: action.payload }; break;
    case "setMachineAdminstate": state ={...state, machineadmindatas: action.payload }; break;
    case "setInvested": state ={...state, investdatas: action.payload }; break;
    case "setCsvpayment": state ={...state, csvpayment: action.payload }; break;
    case "setCsvpaymentr": state ={...state, csvpaymentr: action.payload }; break;
     // userdatas
      // "name": b.name,
      // "surename": b.surename,
      // "memberid": b.memberid,
      // "type": b.type,
      // "machines": mid,
      // "contract": uexp,
      // "notexp": notexp,
      // "notpayback": notpayback,
      // "totalcomm": b.totalcomm, //ยอดขายเมื่อวานนี้
      // "invested": b.invested, //ลงทุนไปแล้ว 
      // "received_per": b.received_per, //ได้รับผลตอบแทนแล้ว %
      // "avgmonth_received": b.avgmonth_received, //ผลตอบแทนเฉลี่ยเดือนนี้
      // "trendmonth_received": b.trendmonth_received, //แนวโน้มทั้งเดือน
      // "trendlastmonth_received": b.trendlastmonth_received, //แนวโน้มเดือนที่แล้ว
      // "change_received": b.change_received, //เปลี่ยนแปลง
      // "receivedfromcompany": b.receivedfromcompany, //ได้รับผลตอบแทนจากบริษัทแล้ว
      // "receivedsincesetting": b.receivedsincesetting, //ผลตอบแทนตั้งแต่ติดตั้งตู้ถึงวันที่ปัจจุบัน
      // "salelowerrate": b.salelowerrate, //ยอดขายต่ำกว่าขั้นต่ำ
      // "saleuperrate": b.saleuperrate, //ยอดขายเกินขั้นต่ำ
      // "saleuperrate_per": b.saleuperrate_per, //จำนวนตู้ที่ขายได้เกินขั้นต่ำ(%)
      // "salelowerrate_per": b.salelowerrate_per, //จำนวนตู้ที่ขายได้ต่ำกว่าขั้นต่ำ(%)
      // "saleuperrate_mch": b.saleuperrate_mch, //[]หมายเลขตู้ที่ขายเกินขั้นต่ำ
      // "salelowerrate_mch": b.salelowerrate_mch, //[]หมายเลขตู้ที่ขายต่ำกว่าขั้นต่ำ
      // "Timestamp": b.Timestamp
    default:
  }
  return state;
}

const initialdata1 ={ chartdata:[]}
const ChartReducer = (state=initialdata1, action) =>{
  switch(action.type){
    case "setChartdata": state ={...state, chartdata: action.payload }; break;
    default:
  }
  return state;
}

const initialdata3 ={ chartdata2:[]}
const ChartReducer2 = (state=initialdata3, action) =>{
  switch(action.type){
    case "setChartdata2": state ={...state, chartdata2: action.payload }; break;
    default:
  }
  return state;
}

const initiallogusers ={ logusers:[]}
const LogusersReducer = (state=initiallogusers, action) =>{
  switch(action.type){
    case "setLogusers": state ={...state, logusers: action.payload }; break;
    default:
  }
  return state;
}

const initiallogmchs ={ logmchs:[]}
const LogmchsReducer = (state=initiallogmchs, action) =>{
  switch(action.type){
    case "setLogmchs": state ={...state, logmchs: action.payload }; break;
    default:
  }
  return state;
}

//ดีบักโค้ดว่าทำงานส่วนไหนบ้าง
// const mylogger =(store)=>(next)=>(action)=>{
//   console.log("Log Action ", action)
//   next(action)
// }

// const store = createStore(employeeReducer)// ระบุ reducer ลงไปได้เลยเมื่อมี reducer ตัวเดียว
const store = createStore(combineReducers({
  loginstatus: loginReducer,
  changeState: changeState,
  data: DataReducer,
  chartdata: ChartReducer,
  chartdata2: ChartReducer2,
  logusers: LogusersReducer,
  logmchs: LogmchsReducer
})) //แสดง action ที่ทำงานอยู่

//ใช้ดูการเปลี่ยนแปลงของ state
store.subscribe(()=>{
  console.log("update store ", store.getState())
})

export default store


// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store